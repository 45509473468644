<template>
    <b-card no-body class="mb-0  mt-2">
        <b-overlay :show="loading" rounded="sm" no-fade>
            <div class="m-2">
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="9">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="filter" class="d-inline-block mr-1" cle placeholder="Search..." />
                            <b-button variant="primary" class="text-nowrap" @click="addNewFile()">
                                <span>Add Quote</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table
                striped
                hover
                class="position-relative"
                :items="listQuotes"
                :filter="filter"
                filter-debounce="250"
                :filter-included-fields="filterOn"
                selectable
                select-mode="single"
                responsive
                :small="true"
                head-variant="dark"
                :fields="tableColumns"
                show-empty
                empty-text="No matching records found"
                :current-page="currentPage"
                :per-page="perPage"
            >
                <!-- @row-selected="quotePreview" -->
                <!-- Column: id -->
                <template #cell(id)="data">
                    <strong class="text-primary">#{{ Number(data.item.id ? data.item.id : 0) }}</strong>
                </template>
                <!-- Column: file name -->
                <template #cell(fileName)="data"> {{ data.item.fileName ? data.item.fileName : "N/A" }} </template>

                <!-- Column: Company -->
                <template #cell(company)="data">
                    <span v-if="data.item.companyId != -1">{{ data.item.company ? data.item.company.name : "N/A" }}</span>
                    <span v-else>All Companies</span>
                </template>

                <!-- Column: User -->
                <template #cell(user)="data">
                    <span v-if="data.item.userId != -1">{{ data.item.user ? data.item.user.name + " " + data.item.user.surname : "N/A" }}</span>
                    <span v-else>All Users</span>
                </template>

                <!-- Column: created_at -->
                <template #cell(createDate)="data"> {{ data.item.created_at ? dateFormat(data.item.created_at) : "N/A" }} </template>

                <!-- Column: updated_at -->
                <template #cell(updateDate)="data"> {{ data.item.updated_at ? dateFormat(data.item.updated_at) : "N/A" }} </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <!-- @click="quoteDownload(data.item.fileLink)" -->
                    <b-button variant="primary" target="_blank" @click="saveFile(data.item.fileLink)" class="btn-icon" size="sm">
                        <feather-icon icon="ArrowDownIcon" size="16" style="color:white" />
                    </b-button>

                    <b-button variant="warning" target="_blank" @click="fileEdit(data.item)" class="btn-icon  " style="margin-left:2px" size="sm">
                        <feather-icon icon="UsersIcon" size="16" style="color:white" />
                    </b-button>

                    <b-button variant="danger" target="_blank" @click="deleteFile(data.item)" class="btn-icon " style="margin-left:2px" size="sm">
                        <feather-icon icon="XIcon" size="16" style="color:white" />
                    </b-button>
                </template>
            </b-table>

            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                    </b-col>

                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>

            <!-- Add New Quote -->
            <b-modal
                id="modal-file"
                cancel-variant="outline-secondary"
                ok-title="Save File"
                @ok="handleOk"
                @show="resetModal"
                @hidden="resetModal"
                cancel-title="Close"
                :no-close-on-backdrop="true"
                centered
                title="Add New Quote"
            >
                <b-form ref="fileRules">
                    <b-form-group :state="nameState" invalid-feedback="File Name is required">
                        <label for="fileName">File Name:</label>
                        <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
                    </b-form-group>
                    <b-form-group :state="fileState" invalid-feedback="File is required">
                        <b-form-file type="file" :state="fileState" v-model="tempFile" required placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                    </b-form-group>

                    <b-form-group :state="companyState" v-if="userData.role == 'supermanager'" invalid-feedback="Company is required">
                        <label for="fileName">Company:</label>
                        <v-select
                            style="width:100%;"
                            v-model="modalCompany"
                            :value="modalCompany"
                            :reduce="(val) => val.id"
                            :options="companyList"
                            label="title"
                            :state="companyState"
                            required
                            :clearable="false"
                        >
                        </v-select>
                    </b-form-group>
                </b-form>
                <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                    <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                </b-col>
            </b-modal>

            <!-- Update Quote -->
            <b-modal
                id="modal-file-update"
                cancel-variant="outline-secondary"
                ok-title="Change File"
                @ok="handleUpdate"
                @hidden="resetModal"
                cancel-title="Close"
                :no-close-on-backdrop="true"
                centered
                title="Update Quote"
            >
                <b-form ref="fileRules">
                    <b-form-group :state="nameState" invalid-feedback="File Name is required">
                        <label for="fileName">File Name:</label>
                        <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
                    </b-form-group>

                    <b-form-group :state="companyState" v-if="userData.role == 'supermanager'" invalid-feedback="Company is required">
                        <label for="fileName">Company:</label>
                        <v-select
                            style="width:100%;"
                            v-model="modalCompany"
                            :value="modalCompany"
                            :reduce="(val) => val.id"
                            :options="companyList"
                            label="title"
                            :state="companyState"
                            required
                            :clearable="false"
                        >
                        </v-select>
                    </b-form-group>
                </b-form>
                <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                    <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                </b-col>
            </b-modal>
        </b-overlay>
    </b-card>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import quotesStoreModule from "../quotesStoreModule";
import vSelect from "vue-select";
import router from "@/router";
import moment from "moment";
import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import pdf from "vue-pdf";
import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";

export default {
    components: {
        BOverlay,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BFormRating,
        vSelect,
        pdf,
        PDFViewer,
    },

    setup() {
        const QUOTES_APP_STORE_MODULE_NAME = "quotes";
        // Register module
        if (!store.hasModule(QUOTES_APP_STORE_MODULE_NAME)) store.registerModule(QUOTES_APP_STORE_MODULE_NAME, quotesStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(QUOTES_APP_STORE_MODULE_NAME)) store.unregisterModule(QUOTES_APP_STORE_MODULE_NAME);
        });
    },

    data() {
        return {
            directoryPath: "Quotes/",
            tempFile: null,
            pdfLink: null,
            listQuotes: [],

            companyList: [],
            modalFileName: null,
            modalFile: null,
            modalCompany: null,
            userData: JSON.parse(localStorage.getItem("userData")),
            filterOn: ["id", "fileName", "user", "createDate", "updateDate"],
            tableColumns: [
                { key: "id", sortable: true, class: "text-center", thStyle: "width: 5%" },
                { key: "fileName", sortable: true, class: "text-center", thStyle: "width: 30%" },
                { key: "company", sortable: true, class: "text-center", thStyle: "width: 15%" },
                { key: "user", sortable: true, class: "text-center", thStyle: "width: 15%" },
                { key: "createDate", label: "CREATED DATE", sortable: true, class: "text-center", thStyle: "width: 10%" },
                { key: "updateDate", labe: "UPDATED DATE", sortable: true, class: "text-center", thStyle: "width: 10%" },
                { key: "actions", sortable: false, class: "text-center", thStyle: "width: 15%" },
            ],
            perPage: localStorage.getItem("quoteShow") ?? 10,
            currentPage: 1,
            totalRows: 0,

            perPageOptions: [10, 25, 50, 100],
            filter: null,
            loading: false,
            percentCompleted: 0,
            fileURL: store.state.app.fileURL,

            download: false,
        };
    },

    watch: {
        perPage: {
            handler: function(val, before) {
                localStorage.setItem("quoteShow", val);
            },
        },
    },

    methods: {
        saveFile(linkData) {
            this.loading = true;

            axiosIns
                .post("getDownload", { link: linkData }, { responseType: "blob" })
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));

                    const link = document.createElement("a");
                    link.href = url;

                    link.setAttribute("download", linkData.substring(7));

                    document.body.appendChild(link);
                    link.click();
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        fetchQuotes() {
            this.loading = true;
            store
                .dispatch("quotes/fetchQuotes", [])
                .then((res) => {
                    this.listQuotes = res.data.data;
                    this.totalRows = res.data.data.length;

                    this.dropdownCompanyAll();
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        dropdownCompanyAll() {
            store
                .dispatch("quotes/dropdownCompanyAll", [])
                .then((res) => {
                    res.data.unshift({
                        id: -1,
                        title: "All Companies",
                    });
                    this.companyList = res.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    console.log(error);
                });
        },

        addNewFile() {
            this.$bvModal.show("modal-file");
        },

        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();

            if (this.percentCompleted == 0) {
                if (!this.checkFileValidity()) {
                    return;
                }

                this.loading = true;
                this.uploadFilesSequentially(0);
            }
        },

        uploadFilesSequentially(index) {
            let element = this.tempFile;
            let formData = new FormData();

            formData.append("file", element);
            formData.append("path", this.directoryPath);

            const config = {
                onUploadProgress: function(progressEvent) {
                    this.percentCompleted = Math.round((progressEvent.loaded * 99) / progressEvent.total);
                }.bind(this),
            };

            axiosIns
                .post("uploadQuoteFile", formData, config)
                .then((response) => {
                    this.modalFile = response.data;
                    this.percentCompleted = 100;

                    if (this.percentCompleted == 100) {
                        var data = {
                            userId: -1,
                            fileName: this.modalFileName,
                            fileLink: "Quotes/" + this.modalFile,
                            companyId: this.modalCompany,
                        };
                        // Trigger submit handler
                        this.modalOk(data);
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },

        handleUpdate(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.modalUpdate();
        },

        modalOk(data) {
            this.$nextTick(() => {
                this.$bvModal.hide("modal-file");
            });

            axiosIns
                .post("saveQuoteFile", data)
                .then((res) => {
                    this.listQuotes = res.data.data;
                    this.totalRows = res.data.data.length;

                    this.modalFileName = null;
                    this.modalFile = null;
                    this.modalCompany = null;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },

        modalUpdate(val) {
            if (!this.checkFormValidity()) {
                return;
            }

            this.$nextTick(() => {
                this.$bvModal.hide("modal-file-update");
            });

            this.loading = true;

            var data = {
                id: this.modalId,
                fileName: this.modalFileName,
                fileLink: this.modalFile,
                userId: -1,
                companyId: this.modalCompany,
            };

            axiosIns
                .put("updateQuoteFile", data)
                .then((res) => {
                    this.listQuotes = res.data.data;
                    this.totalRows = res.data.data.length;

                    this.modalFileName = null;
                    this.modalFile = null;
                    this.modalCompany = null;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },

        fileSelected(event) {
            //  this.loading = true;

            let formData = new FormData();
            formData.append("file", event);

            const config = {
                onUploadProgress: function(progressEvent) {
                    this.percentCompleted = Math.round((progressEvent.loaded * 99) / progressEvent.total);
                }.bind(this),
            };

            axiosIns
                .post("uploadQuoteFile", formData, config)
                .then((response) => {
                    this.modalFile = response.data;

                    this.percentCompleted = 100;

                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },

        fileEdit(data) {
            this.modalId = data.id;
            this.modalCompany = data.companyId;
            this.modalFileName = data.fileName;
            this.$bvModal.show("modal-file-update");
        },

        deleteFile(data) {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.loading = true;

                    axiosIns
                        .delete(`deleteQuoteFile/${data.id}`)
                        .then((res) => {
                            this.listQuotes = res.data.data;
                            this.totalRows = res.data.data.length;

                            this.loading = false;
                        })
                        .catch((error) => {
                            console.log("error delete ", error);
                        });
                }
            });
        },

        resetModal() {
            this.modalFileName = null;
            this.modalFile = null;
            this.modalCompany = null;
            this.percentCompleted = 0;
        },

        checkFormValidity() {
            const nameValid = this.$refs.fileRules.checkValidity();

            if (this.userData.role == "user") {
                return nameValid;
            } else {
                return nameValid && this.modalCompany != null;
            }
        },

        checkFileValidity() {
            const nameValid = this.$refs.fileRules.checkValidity();
            if (this.userData.role == "user") {
                return nameValid;
            } else {
                return nameValid && this.fileState && this.modalCompany != null;
            }
        },

        dateFormat(val) {
            return moment(String(val)).format("DD-MM-YYYY HH:mm");
        },
    },

    created() {
        this.fetchQuotes();
    },

    computed: {
        dataMeta() {
            return {
                from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
                to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
                of: this.totalRows,
            };
        },

        nameState() {
            return this.modalFileName != null && this.modalFileName != "" ? true : false;
        },

        fileState() {
            return this.tempFile != null && this.tempFile != "" ? true : false;
        },
        companyState() {
            return this.modalCompany != null && this.modalCompany != "" ? true : false;
        },
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
